<template>
  <entity-card
    :tabs="tabs"
    :tabs-items="tabsItems"
    :title="item.name"
    :image="image"
    :actions="actions"
    :loading="loading"
    :show-back-btn="canBack"
    @click:close="handleClose"
    @click:back="handleBack"
  >
    <template #header:status-bar>
      <div class="mr-2" :class="{ 'red--text': !item.enabled }">
        {{ item.enabled ? 'Enabled' : 'Disabled' }}
      </div>
    </template>
  </entity-card>
</template>

<script>
import EntityCard from '@/components/entity-card';
import {
  computed,
  onMounted,
  provide,
  toRefs,
  watch
} from '@vue/composition-api';

import { useGeotagMenu } from '@/modules/geotags/compositions/geotagMenu';

import { useGeotag } from '@/modules/geotags/compositions/geotag';
import { useMap } from '@/compositions/map';
import useHistoryStack from '@/compositions/history-stack';

export default {
  name: 'GeotagCard',
  components: {
    EntityCard
  },
  props: {
    geotagId: {
      type: String,
      required: true
    }
  },
  setup(props, { emit }) {
    const { geotagId } = toRefs(props);

    const { hasPrevRoute: canBack } = useHistoryStack();

    const { geotags } = useMap();

    const { item, load, loading } = useGeotag(geotagId);

    const image = computed(() => item.value.url || '/img/object.png');

    provide('entity', item);
    provide('entityId', props.geotagId);

    watch(
      () => item.value,
      value => {
        geotags.setGeotags([value]);
        geotags.setMapToGeotagCenter(geotagId.value);
      }
    );

    onMounted(async () => {
      load();
    });

    const tabs = [
      {
        id: 'general',
        title: 'General'
      },
      {
        id: 'media',
        title: 'Media'
      },
      {
        id: 'notifications',
        title: 'Notifications'
      }
    ];
    const tabsItems = [
      () => import('./general/index'),
      {},
      {
        component: () =>
          import('@/modules/notifications/ui/RecentlyNotificationList'),
        props: { objectId: props.geotagId }
      }
    ];

    const itemActions = computed(() => useGeotagMenu({ entity: item.value }));

    const handleClose = () => {
      geotags.unselectGeotag(geotagId.value);
      emit('stack:close');
    };
    const handleBack = () => {
      emit('stack:back');
    };

    return {
      tabs,
      image,
      actions: itemActions,
      handleClose,
      handleBack,
      canBack,
      tabsItems,
      loading,
      item
    };
  }
};
</script>
